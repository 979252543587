<template>
  <div class="add_page">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <!--      等级-->
      <el-form-item label="等级" prop="grade">
        <el-select v-model="ruleForm.grade" placeholder="请选择等级">
          <el-option
            v-for="item in gradeSelection"
            :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!--分数-->
      <el-form-item label="分数" prop="fraction">
        <el-col :span="5">
          <el-input v-model.number="ruleForm.fraction" placeholder="请输入分数"></el-input>
        </el-col>
      </el-form-item>
      <!-- 编辑器 -->
      <el-form-item label="题干" prop="stem">
        <el-col :span="18">
          <tinymce-editor class="tinymce-editor" v-model="ruleForm.stem" :init="init"/>
        </el-col>
      </el-form-item>

      <!--选项-->
      <div class="form_add_problem" v-for="(item,index) in ruleForm.domains" :key="item.key">
        <!--问题-->
        <el-form-item label="问题"
                      :prop="'domains.' + index + '.problem'"
                      :rules="{
                      required: true, message: '问题不能为空', trigger: 'blur'
                    }"
        >
          <el-col :span="5">
            <el-input v-model="item.problem" placeholder="请输入问题"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="选项" :prop="'domains.' + index + '.options'"
                      :rules="{
                      required: true, message: '选项不能为空', trigger: 'blur'
                    }">
          <el-col :span="5">
            <el-input v-model="item.options" placeholder="请输入选项"></el-input>
          </el-col>

          <el-col :span="4" style="margin-left: 20px;">
            <el-form-item :prop="'domains.' + index + '.radio'" :rules="{
                      required: true, message: '请至少选择一个 ', trigger: 'blur'
                    }">
              <el-radio-group v-model="item.radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

        </el-form-item>
        <el-button class="add_problem" type="primary" v-show="index === 0" @click="addProblem">新增</el-button>
        <el-button v-show="ruleForm.domains.length > 1" class="delete_problem" type="danger"
                   @click="deleteProblem(index)">删除
        </el-button>
      </div>
      <!--提交      -->

      <el-form-item style="text-align: left; margin-top: 100px;">
        <el-button type="primary" @click="submitForm('ruleForm')">创建</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>

    <!--      <router-link to='/admin/level'>跳转</router-link>-->
  </div>

</template>

<script>
import TinymceEditor from '@/components/TinymceEditor';

export default {
  components: {TinymceEditor},
  data() {
    return {
      value: '',
      init: {},
      ruleForm: {
        grade: '',
        fraction: '',
        stem: '',
        // 问题 - 选项
        domains: [
          {problem: '', options: '', radio: '0'},
        ],
      },
      // 等级下拉数据
      gradeSelection: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      rules: {
        grade: [
          {required: true, message: '请选择等级', trigger: 'change'},
        ],
        fraction: [
          {required: true, message: '请输入分数'},
          {type: 'number', message: '分数必须为数字值'}
        ],
        stem: [
          {required: true, message: '请输入题干内容', trigger: 'change'}
        ],

      }
    };

  },
  mounted() {
    this.init = {
      height: 400,
    }
  },
  methods: {
    submitForm(formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          console.log(_this.ruleForm)
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 删除
    deleteProblem(index) {
      this.ruleForm.domains.splice(index, 1)
    },
    // 添加
    addProblem() {
      this.ruleForm.domains.push({
        problem: '',
        options: '',
        radio: '0',
        key: Date.now()
      })
    }
  }
}
</script>
<style lang="scss">
.add_page {
  .demo-ruleForm {
    margin-top: 30px;

    .form_add_problem {
      position: relative;

      .add_problem,
      .delete_problem {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateY(-50%);
      }

      .delete_problem {
        right: 40%;
      }
    }
  }

  .edit_form {
    padding: 0 100px;
  }
}

</style>


